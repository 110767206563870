<template>
    <div class="confirm-mobile">
        <div class="mobile-img">
            <svg @click="$router.push('/confirm-mobile').catch(() => {})" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <g id="Group_2278" data-name="Group 2278" transform="translate(-131 -108)">
                    <g id="Rectangle_1140" data-name="Rectangle 1140" transform="translate(131 108)" fill="#fff" stroke="#707070" stroke-width="0.5">
                    <rect width="34" height="34" rx="4" stroke="none"/>
                    <rect x="0.25" y="0.25" width="33.5" height="33.5" rx="3.75" fill="none"/>
                    </g>
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.662,12.972l5.295-5.127a.944.944,0,0,0,0-1.369,1.027,1.027,0,0,0-1.416,0l-6,5.809a.946.946,0,0,0-.029,1.336l6.024,5.85a1.029,1.029,0,0,0,1.416,0,.944.944,0,0,0,0-1.369Z" transform="translate(132.749 111.806)"/>
                </g>
            </svg>
            <img src="@/assets/images/elements/otp.png" alt="Mobile">
        </div>
        <h5>التحقق رقم الهاتف</h5>
        <p>من فضلك ادخل ال6 ارقام المرسلة إلي هاتفك المسجل في حسابك الحالي</p>
        <h6>{{ phone }}********</h6>
        <div  dir="ltr" class="confirm-input">
            <div style="display: flex; gap: 12px; justify-content: center;"  :class="!successData ? 'err-otp' : ''">
                <input type="number" maxlength="1" @input="checkLength" name='code' class='code-input' required />
                <input type="number" maxlength="1" @input="checkLength" name="code" class="code-input" required />
                <input type="number" maxlength="1" @input="checkLength" name="code" class="code-input" required />
                <input type="number" maxlength="1" @input="checkLength" name="code" class="code-input" required />
                <input type="number" maxlength="1" @input="checkLength" name="code" class="code-input" required />
                <input type="number" maxlength="1" @input="checkLength" name="code" class="code-input" required />
            </div>
            <span class="err-msg text-center" style="display: block;" v-if="!successData">{{ error_msg }}</span>
            <h5 v-if="countDown > 0">{{ "يمكنك إعادة إرسال الكود خلال " + countDown + " ثانية " }}</h5>
            <h5 @click="resendOtp()" v-else style="display: flex; align-items: center; justify-content: center;">
                إعادة إرسال الكود
                <svg  class="mr-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                    <path id="Icon_ionic-md-refresh" data-name="Icon ionic-md-refresh" d="M11.625,16.125a4.5,4.5,0,1,1,0-9,4.359,4.359,0,0,1,3.15,1.35l-2.4,2.4h5.25V5.625L15.863,7.388A5.992,5.992,0,1,0,17.4,13.261H15.813A4.468,4.468,0,0,1,11.625,16.125Z" transform="translate(-5.625 -5.625)" fill="#292929"/>
                </svg>
            </h5>
            <button @click="googleEventTrack('الضغط على التحقق من الرقم', 'login', 'check_otp_code'), checkCode()" :class="code.length < 6 ? 'opacity-btn1' : ''" :disabled="code.length < 6" v-if="!loading">تحقق من الرقم</button>
            <button class="btn-login w-full flex justify-center items-center" v-else> <loadSpiner></loadSpiner> </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import loadSpiner from "@/views/components/table-components/SpinnerColor2"
import gTag from "@/mixins/gtag.js"

export default {
    mixins: [gTag],
    data () {
        return {
            code: '',
            loading: false,
            successData: true,
            countDown: 60
        }
    },
    computed: {
        ...mapGetters("auth", ["phone", "email", "success", "error_msg"]),
    },
    components: {
        loadSpiner
    },
    methods: {
        ...mapActions("auth", ["checkOtp", "forgetPassword"]),
        checkLength(e) {
            if (e.target.value.length > e.target.maxLength){ 
                e.target.value = e.target.value.slice(0, e.target.maxLength);
            }
        },
        inputsCodeVerifection() {
            const inputElements = [...document.querySelectorAll('input.code-input')]
            inputElements.forEach((ele, index) => {
                ele.addEventListener('keydown',(e) => {
                    if(e.keyCode === 8 && e.target.value === ''){
                        inputElements[Math.max(0,index-1)].focus()
                    }
                })
                ele.addEventListener('input',(e) => {
                    if(e.keyCode !== 8 && e.target.value !== '' && index < inputElements.length -1 ){
                        inputElements[Math.max(0,index+1)].focus()
                    }
                })
                ele.addEventListener('input',(e) => {
                    if(e.keyCode !== 8 && e.target.value !== ''){
                        inputElements[Math.max(0,index)].classList.add("active-code")
                        if(this.code.length < 6) {
                            this.code += e.target.value
                        }
                    }else {
                        inputElements[Math.max(0,index)].classList.remove("active-code")
                        this.code = this.code.slice(0, -1);
                    }
                })
            })
        },
        async checkCode() {
            this.loading = true
            const inputElements = [...document.querySelectorAll('input.code-input')]
            this.code = ""
            inputElements.forEach((ele) => {
                this.code += ele.value
            })
            let obj = {
                code: this.code,
                email: this.email
            }
            await this.checkOtp(obj)
            if(this.success) {
                this.successData = true
                this.$router.push('/change-password').catch(() => {})
            }else {
                this.successData = false
            }
            this.loading = false
        },
        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        async resendOtp () {
            if (this.countDown > 0) {
                return false
            } else {
                await this.forgetPassword(this.email);
                this.countDown = 60;
                this.countDownTimer();
            }
        }
    },
    mounted () {
        this.countDownTimer();
        this.inputsCodeVerifection();
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_login.scss';
</style>